<template>
  <div>
    <wx-open-launch-weapp username="gh_6ecd9bae909f">
      <script type="text/wxtag-template">
        <style>.btns{background: #ccc}</style>
        <span class="btns">打开小程序</span>
      </script>
    </wx-open-launch-weapp>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
</style>